.add-product-container > .admin-container-add-product {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    color: #1A1A1A;
    text-align: center;
    margin-right: 1rem;
}

.add-product-container {
    background: #FFFFFF;
    width: 100%;
    padding-bottom: 0.1rem;
}

.admin-container-add-product > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.admin-container-add-product > div:first-child > div:first-child {
    margin-left: 1rem;
    margin-top: 1rem;
}

.admin-container-add-product > div:last-child > span {
    display: inline-block;
    border: 1px solid #BCBDBC;
    width: 95%; 
    text-align: center;
    margin-bottom: 1rem;
}

.add-product-form {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #1A1A1A;
    /* min-height: 100vh; */
}

.add-product-form .form-group {
    width: 85%;
    margin: 0 auto;
    padding-bottom: 1rem;
  }

  .add-product-form .form-group input {
    margin-top: 0.5rem;
  }

  .add-product-form .form-group:last-child {
    /* height: 300px; */
  }

  .add-product-form .form-group-two-cols label {
    margin-right: 1rem;
  }

  .form-group-two-cols {
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;
  }
  
  .form-group-two-cols > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .form-group-two-cols > div > label {
    width: 75%;
  }
  
.order-details-button-container.create-product {
  margin: 6.5rem auto;
}

.add-product-img-container {
  position: relative;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.add-product-img-container > img {
  border: 2px solid #eaebf8;
  border-radius: 2px;
  transition: all 0.2s ease;
}

.add-product-img-container > img:hover {
  border-color: #7aafec;
}

.add-product-img-container > svg {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.5rem;
  transition: color 0.1s ease;
}

.add-product-img-container > svg:hover {
  cursor: pointer;
  color: #E53E3E;
}