.App {
  text-align: center;
}

button {
  -webkit-tap-highlight-color: transparent;
}

.routes { 
  padding-top: 61px;
}

.routes.home {
  padding-top: 0;
}

.main-content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.routes,
.home-container {
  flex-grow: 1;
}



@media (max-width: 980px) {
  .copyright {
    padding-top: 2.7rem;
    text-align: center;
    background: #E9E4CA;
  }
}