.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .sort-icons-container-headers {
    display: flex;
    align-items: center;
    gap: 4px;
  }