.login-container {
    background: #F9F9F9;
    padding: 1rem 0;
    padding-bottom: 0;
}

.login-wrapper .alternative-link {
    max-width: 320px;
}

.login-wrapper form {
    max-width: 400px;
    margin: 0 auto;
}

.login-container .login-fields-container {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    margin-top: 1rem;
    margin-left: 20px;
}

.input-fields {
    margin-top: 1rem;
    width: 95%;
}

.input-fields > div:first-child {
    font-weight: 400;
    font-size: 16px;
    margin-left: 1rem;
    margin-bottom: 0.5rem;
}

.input-field-submit-container {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input-field-submit-container > button {
    width: 80%;
    height: 48px;
    background: #2C621A;
    border-radius: 0.25rem;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    transition: all 0.2s ease;
}

.input-field-submit-container > button:hover {
    background: #3E7C2B;
}

.forgot-password {
    margin-top: 1rem;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #2D3748;
    text-decoration: none;
}

.forgot-password:hover {
    color: #718096;
}

.alternative-link-container {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    min-height: 39vh;
}

.alternative-link-container h2 {
    margin-top: 2rem;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
}

.alternative-link {
    margin-top: 5rem;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #fff;
    text-decoration: none;
    background: #2C621A;
    border-radius: 0.25rem;
    width: 80%;
    height: 48px;
    transition: all 0.2s ease;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.alternative-link:hover {
    background: #3E7C2B;
}

.alternative-link > div {
    text-align: center;
    flex-grow: 1;
    margin-left: 2.2rem;
  }
  
  .alternative-link > svg {
    margin-right: 1rem;
  }