.product-card-image-details-container {
    display: grid;
    grid-template-columns: 105px 1fr;
    align-items: center;
}

.product-card-image-details-container > div:first-child {
    height: 95px;
    background: #F1F1F1;
    border: 1px solid #000000;
    border-radius: 5px;
    margin-left: 10px;
    margin-bottom: 10px;
}

.product-card-image-details-container-desktop {
    height: 95px;
    background: #F1F1F1;
    border: 1px solid #000000;
    border-radius: 5px;
    width: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-card-image-details-container-desktop > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}
