.shop-container {
    min-height: 90vh;
    position: relative;
    padding-bottom: 4rem;
}

.shop-container > h1 {
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 32px;
    margin-top: 0.75rem;
    margin-left: 0.75rem;
}

.shop-container .filter-sort-search-container {
    width: 70%;
    max-width: 400px;
    margin: 1rem auto;
}

.shop-container .admin-page-results-container {
    color: #456649;
    border-color: #456649;
}

.shop-container .admin-page-results-container div {
    font-weight: 500;
}

.shop-chakra-filters {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 0.75rem;
  }

.shop-chakra-filters::-webkit-scrollbar {
    display: none;
}

.chakra-filter {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.chakra-filter img {
    width: 80px;
    height: 80px;
    margin: 0 3.2rem;
}

.chakra-filter span {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 13px;
    margin-top: 0.5rem;
}

.shop-chakra-filters > .chakra-filter:first-child > span{
    color: #456649;
}

.shop-chakra-filters > .chakra-filter:nth-child(2) > span{
    color: #E60000;
}

.shop-chakra-filters > .chakra-filter:nth-child(3) > span{
    color: #BF5700;
}

.shop-chakra-filters > .chakra-filter:nth-child(4) > span{
    color: #7A6600;
}

.shop-chakra-filters > .chakra-filter:nth-child(5) > span{
    color: #5C7300;
}

.shop-chakra-filters > .chakra-filter:nth-child(6) > span{
    color: #007B9B;
}

.shop-chakra-filters > .chakra-filter:nth-child(7) > span{
    color: #00489C;
}

.shop-chakra-filters > .chakra-filter:last-child > span{
    color: #8F009C;
}

@media (min-width: 980px) {
    .shop-chakra-filters {
        justify-content: center;
        margin-left: 5%;
        gap: 1rem;
        overflow-x: visible;
        white-space: normal;
    }

    .chakra-filter {
        transition: all 0.2s ease-in-out;
    }

    .chakra-filter:hover {
        transform: scale(1.1);
    }

    .chakra-filter img {
        margin: 0 1rem;
    }

    .shop-container > h1 {
        font-size: 48px;
    }

    .search-and-filters-container {
        display: flex;
    }

    .shop-display-products-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 10px;
        max-width: 2560px;
        margin: 0 auto;
    }
    .shop-container .filter-sort-search-container {
        margin-left: 2rem;
        width: 100%;
        margin-top: 2rem;
    }
}

@media (min-width: 520px) and (max-width: 979px) {
    .shop-display-products-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 519px) {
    .shop-display-products-container {
        display: grid;
        grid-template-columns: 1fr;
    }
}