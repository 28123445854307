.cart-container {
    min-height: 86vh;
}

.cart-container h1 {
    font-family: 'Roboto';
    font-size: 2rem;
    font-weight: 600;
    margin: 0.5rem 1rem;
}

.cart-products-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.cart-container .subtotal-container {
    display: flex;
    justify-content: space-between;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    margin: 1rem;
}

.checkout-btn-container {
    display: flex;
    width: 90%;
    margin: 0 auto;
    padding-bottom: 2.5rem;
}

.checkout-btn-container > a {
    text-align: center;
    line-height: 48px;
}

.empty-cart {
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 28px;
    text-align: center;
    margin: 32px auto;
}

.empty-cart > h2 > button {
    text-decoration: underline;
}

.empty-cart > h2 > button:hover {
    cursor: pointer;
    /* choose a nice gray color */
    color: #808080;
}

@media (min-width: 620px) {
    .cart-products-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding-bottom: 1rem;
    }
}

@media (min-width: 980px) {
    .cart-wrapper {
        display: flex;
        justify-content: center;
        gap: 1rem;
    }
    .cart-first-wrapper {
        width: 55%;
    }
    .cart-products-container {
        grid-template-columns: 1fr;
    }
    .cart-second-wrapper {
        margin-top: 4rem;
        width: 40%;
    }
}

@media (min-width: 1200px) {
    .cart-products-container {
        grid-template-columns: 1fr 1fr;
    }
}