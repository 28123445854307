.order-history-wrapper {
    min-height: 86vh;
    position: relative;
    margin-top: 0.75rem;
    padding-bottom: 4rem;
}

.order-history-wrapper > div > h1 {
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 32px;
    margin-left: 0.75rem;
    margin-bottom: 0.75rem;
}

.order-history-wrapper .filter-sort-search-container {
    width: 70%;
    max-width: 400px;
    margin: 1rem auto;
}

.customer-orders-wrapper {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    justify-items: center;
    margin: 6px;
}

@media (min-width: 620px) {
    .customer-orders-wrapper {
        grid-template-columns: 1fr 1fr;
    }
}

@media (min-width: 980px) {
    .customer-orders-wrapper {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .order-history-wrapper > div:first-child {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 1rem;
    }
    .order-history-wrapper > div:first-child .filter-sort-search-container {
        margin: 0 auto;
        margin-bottom: 0.5rem;
        width: 100%;
    }
    .order-history-wrapper > div > h1 {
        margin-left: 0;
    }
    .order-history-wrapper {
        min-height: 90vh;
    }
}