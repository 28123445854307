.admin-filter-sort-component-container {
    background: #F7F9FB;
    padding-bottom: 1rem;
}

.search-and-buttons-container {
    width: 85%;
    margin: 0 auto;
}

.filter-sort-search-container {
    display: flex;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border-radius: 5px;
    padding: 0.5rem;
    margin-bottom: 1rem;
    width: 100%;
    height: 35px;
  }
  
  .filter-sort-search-input {
    flex: 1;
    border: none;
    font-size: 1.3rem;
    padding-left: 0.5rem;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #1A1A1A;
    outline: none;
    overflow-x: hidden;
  }

  .filter-sort-search-input::placeholder {
    color: #ACB0AF;
  }
  
  .filter-sort-search-container FontAwesomeIcon {
    padding: 0.5rem;
  }

.sort-icons-container-headers {
    display: flex;
    flex-direction: column;
}

.sort-icons-container-headers > svg {
    transition: all 0.1s ease-in;
    height: 35px;
    width: 35px;
}

.sort-icons-container-headers > svg:first-child {
    margin-bottom: -20px;
}

.admin-filter-sort-btn-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.admin-filter-sort-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
	cursor: pointer;
    background: #FFFFFF;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 45%;
    height: 35px;
    transition: all 0.3s ease-in-out;
    width: 100%;
    margin: 0 0.5rem;
}

.admin-filter-sort-btn:hover {
    background: #E6F3FF;
}

.admin-filter-sort-btn > svg {
    padding-right: 0.25rem;
}

.admin-filter-sort-btn > div {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #000000;
    padding-left: 0.25rem;
}

.admin-page-results-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #595959;
    border-left: 0;
    border-right: 0;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #595959;
    padding: 0.25rem 0.5rem;
}

.admin-page-results-container > div:nth-child(2) {
    display: flex;
    align-items: center;
}

.admin-page-results-container > div:nth-child(2) > div:last-child {
    width: 3.5rem;
    margin-left: 0.5rem;
}

.admin-desktop-sort {
    display: none;
}

.admin-desktop-sort > div {
    display: flex;
    color: #36454F;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    margin: 0.5rem 2rem 0 1rem;
    align-items: center;
    justify-content: center;
}

.admin-desktop-sort > div > div {
    padding-right: 0.5rem;
}

.admin-desktop-sort > div > svg {
    text-align: center;
}

.admin-filter-sort-btn.desktop > div{
    font-size: 16px;
}

.admin-filter-sort-btn.desktop {
    max-width: 300px;
}

.mobile-filter-sort-container {
    position: fixed; 
    top: 61px;
    left: 0; 
    right: 0; 
    z-index: 1;
    height: calc(100vh - 61px); 
    overflow: auto;
}


@media (min-width: 620px) {
    .search-and-buttons-container {
        display: flex;
        align-items: center;
        width: 92.5%;
    }

    .admin-filter-sort-btn-container {
        width: 100%;
        margin-left: 1.5rem;
    }
}

@media (min-width: 980px) {
    .admin-desktop-sort {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
    }
    .admin-filter-sort-component-container {
        padding-bottom: 0.5rem;
    }
    .admin-filter-sort-btn-container {
        justify-content: flex-start;
    }
}