.invalid-token-link {
    text-decoration: underline;
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
    transition: color 0.3s;
    color: #4a4a4a;
}

/* .invalid-token-link:hover {
    color: #000;
} */