.write-a-review-container > div:first-child {
    font-weight: 500;
    font-size: 32px;
}

.review-form .form-group {
    font-size: 16px;
    font-weight: 500;
}

.reviews-btns-container.review-form {
    display: flex;
    justify-content: center;
}

.add-product-container.review-form {
    border-radius: 5px;
    padding-bottom: 3rem;
}

@media (max-width: 980px) {
    .add-product-container.review-form {
        min-height: 90vh;
    }
}