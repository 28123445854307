.contact-us-container {
    background: #335437;
    min-height: 87vh;
    color: #F2F2F2;
    font-family: 'Roboto';
    font-weight: 300;
    font-size: 16px;
    padding-bottom: 1rem;
}

.contact-us-container h1{
    font-family: 'Roboto';
    font-size: 2rem;
    font-weight: 600;
    padding: 0.5rem 1rem;
    padding-top: 2rem;
}

.contact-us-form {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 600px;
    margin: 0 auto;
}

.label-and-input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.add-to-cart-btn.contact {
    background: #E4F3CA;
    color: #444444;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    transition: all 0.2s ease;
    max-width: 400px;
}

.add-to-cart-btn.contact:hover {
    background: #C9E0A3;
}

@media (min-width: 620px) {
    .contact-us-container h1 {
        text-align: center;
        padding-top: 2rem;
    }
}

@media (min-width: 980px) {
    .contact-us-container {
        min-height: 92.5vh;
    }
}