.bootstrap-carousel .product-card-container {
    width: 70%;
    margin-bottom: 4rem;
}

.carousel-content-wrapper {
    display: flex;
    margin: 0 auto;
    width: 80%;
}

.carousel-content-wrapper .product-card-container {
    width: 40%;
}


@media (min-width: 980px) {
    .carousel-content-wrapper .product-card-container {
        width: calc(24vw);
    }
}