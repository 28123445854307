.admin-dashboard-cards-container {
    background: #F7F9FB;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    justify-items: center;
}

@media (min-width: 980px) {
    .admin-dashboard-cards-container {
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
        margin: 0 2rem;
    }  
}

@media (min-width: 600px) and (max-width: 979px) {
    .admin-dashboard-cards-container {
        grid-template-columns: 1fr 1fr;
    }
}