.checkout-header {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    background: #F9F9F9;
}

.checkout-step-container {
    display: flex;
    gap: 0.25rem;
    font-weight: 600;
    font-size: 14px;
    align-items: center;
}

.checkout-step-container:hover {
    cursor: pointer;
}

.checkout-circle {
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 50%;
    background: #98999A;
    color: #fff;
    text-align: center;
    font-size: 12px;
}

.checkout-circle.selected {
    background: #000000;
}

/* component css */
.checkout-component-container {
    padding: 1rem;
    min-height: 58.5vh;
}

.checkout-component-container h1 {
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 1rem;
}

.checkout-component-form > .input-fields {
    margin: 0 auto;
    margin-bottom: 0.5rem;
    width: 90%;
}

.address-component-header-2 {
    margin-top: 1rem;
}

.checkout-component-form > .alternative-link-container > .alternative-link {
    margin-top: 0;
}

.alternative-link:hover {
    cursor: pointer;
}

/* shipping component css */
.shipping-method-container {
    background: #FFFFFF;
    box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    padding: 1rem;
    padding-bottom:2rem;
    margin-bottom: 0.5rem;
}

.shipping-method-container > label {
    display: flex;
    justify-content: space-between;
}

.radio-container {
    display: flex;
    justify-content: space-between;
}

.radio-container > div:last-child {
    font-weight: 400;
    font-size: 20px;
}

.shipping-type-and-delivery-date-container {
    width: 65%;
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 20px;
    display: flex;
    flex-direction: column;
}

.shipping-type-and-delivery-date-container > div:last-child {
    margin-top: 1rem;
    font-weight: 300;
    font-size: 16px;
}

.alternative-link-container.shipping {
    margin-bottom: 1rem;
}

/* payment component css */
.review-order-container {
    background: #FFFFFF;
    box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    padding: 0.5rem 1rem;
    font-family: 'Roboto';
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 0.5rem;
}

.review-order-container > div:first-child {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
}

.review-order-container > div:first-child > div:last-child {
    font-size: 14px;
    color: #007366;
    cursor: pointer;
}

.review-order-container > div:first-child > div:last-child:hover {
    color: #00868B;
}

.review-order-container > div:last-child {
    text-align: center;
    margin: 1rem auto;
    width: 80%;
}

.order-summary-container {
    margin-bottom: 1rem;
}

.order-summary-container > h2 {
    font-weight: 500;
    font-size: 18px;
}

.order-summary-container div {
    display: flex;
    justify-content: space-between;
    margin-top: 0.25rem;
    font-weight: 500;
}

.order-summary-container div > div:first-child {
    color: #575859;
}

.order-summary-container > div:last-child > div:first-child, .order-summary-container > div:last-child > div:last-child {
    font-weight: 500;
    font-size: 18px;
    color: black;
}

.payment-option-container {
    padding: 0 1rem;
    background: #FFFFFF;
    box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
}

.payment-option-container.stripe {
    padding-bottom: 2rem;
}

.payment-option-container > div:first-child > img {
    width: 96px;
    height: 96px;
}

.payment-option-container > div:first-child {
    display: flex;
    justify-content: space-between;
}

.payment-option-container > div:last-child {
    align-self: center;
    width: 100%;
}

@media (min-width: 980px) {
    .checkout-header {
        display: flex;
        justify-content: center;
        gap: 25%;
    }
    .checkout-component-container.payment {
        display: flex;
        justify-content: space-between;
        gap: 2rem;
        margin: 0 5%;
    }
    .review-order-wrapper {
        width: 45%;
        max-width: 600px;
    }
    .payment-method-wrapper {
        width: 45%;
        max-width: 600px;
    }
    .alternative-link-container.shipping {
        width: 25%;
    }
    
    .shipping-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .checkout-component-container.shipping {
        width: 100%;
        max-width: 600px;
    }

    .checkout-component-container.address {
        display: flex;
        justify-content: center;
        margin: 1rem 0;
        gap: 3rem;
        width: 100%;
    }

    .shipping-address-wrapper {
        width: 40%;
    }

    .contact-wrapper {
        width: 40%;
    }
}