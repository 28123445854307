.error-message.add-to-cart {
    text-align: center;
}

.product-details-container {
    width: 79.5%;
    margin: 0 auto;
    margin-top: 2rem;
}

.product-details-container > .product-image {
    margin: 0 auto;
}

.rating {
    display: flex;
    margin-top: 2rem;
}

.rating > .num-of-reviews {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    margin-left: 0.5rem;
}

.product-details-information-container > .product-details {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 20px;
}

.product-details-information-container > .product-details > h1 {
    margin-top: 0.75rem;
}

.product-details-information-container > .product-details > div:nth-child(2) {
    margin-top: 0.25rem;
    color: #2D3748
}

.product-details-information-container > .product-details > div:nth-child(3) {
    margin-top: 0.75rem;
    font-size: 16px;
}

.product-details-page-functionality {
    display: flex;
    justify-content: space-between;
}

.adjust-quantity-container > div:first-child {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-top: 1rem;
}

.adjust-quantity-container > .adjust-quantity {
    margin-top: 0.5rem;
    width: 142px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #EDF1F6;
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.adjust-quantity-container> .adjust-quantity > input {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 16px;
    color: #1A202C;
    text-align: center;
    width: 40px;
    height: 30px;
}

.adjust-quantity-container > .adjust-quantity > .adjust-quantity-btn {
    background: #F2F2F4;
    border-radius: 5px;
    width: 23px;
    height: 23px;
    display: flex;
    justify-content: center;
    margin: 0 0.5rem;
}

.adjust-quantity.out-of-stock {
    border: none;
    color: #E74C3C;
    font-size: 20px !important;
}

.adjust-quantity-btn > div {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    color: #1A202C;
}

.add-to-favorites {
    background: #FFFFFF;
    border: 1px solid #EDF1F6;
    border-radius: 2px;
    width: 142px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    opacity: 0;
}

.add-to-favorites div {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #1A2A40;
    margin-left: 0.5rem;
}

.add-to-cart-btn {
    width: 100%;
    height: 48px;
    background: #2C621A;
    border-radius: 5px;
    color: #fff;
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 16px;
    margin-top: 2rem;
}

.add-to-cart-btn {
    transition: all 0.2s ease;
}

.add-to-cart-btn:hover {
    background: #3E7C2B;
}

.add-to-cart-btn.out-of-stock {
    background: #E74C3C;
}

.add-to-cart-btn.out-of-stock {
    background: #E74C3C !important;
    cursor: default;
}

@media (min-width: 980px) {
    .product-details-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 90%;
    }

    .product-details-container > .product-image {
        width: 30%;
    }

    .product-details-information-container {
        width: 30%;
        margin: 0 auto;
      }

    .rating > .num-of-reviews {
        /* font-size: 20px; */
    }
    .product-details-information-container > .product-details > h1 {
        font-size: 36px;
    }
    
    .product-details-information-container > .product-details > div:nth-child(2) {
        font-size: 32px;
    }
    
    .product-details-information-container > .product-details > div:nth-child(3) {
        font-size: 20px;
    }

}
