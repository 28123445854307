.change-password-wrapper {
    max-width: 400px;
    margin: 0 auto;
}

.input-field-submit-container.change-password > button {
    max-width: 300px;
}

.error-message {
    color: red;
    margin-top: 1rem;
    font-family: 'Roboto';
}

.success-message {
    color: green;
    margin-top: 1rem;
    font-family: 'Roboto';
}