.order-success-container {
    margin-top: 50px;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    font-family: 'Roboto';
}

.order-success-container h1 {
    font-family: 'Roboto';
    font-size: 2rem;
    font-weight: 700;
}

.order-success-container h2 {
    font-family: 'Roboto';
    font-size: 1.5rem;
    font-weight: 400;
}

.order-success-container h3 {
    font-family: 'Roboto';
    font-size: 1.2rem;
    font-weight: 400;
}

.order-success-container div {
    font-family: 'Roboto';
    text-align: center;
    font-size: 16px;
    font-weight: 500;
}