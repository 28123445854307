* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
}

nav {
    z-index: 999;
}

.sticky-nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #00000000;
    transition: all 0.3s ease;
}

.navbar-container.background {
    background: #637748;
}

.navbar-container .logo-container a {
    color: #000000;
    transition: color 0.3s ease;
}

.navbar-container button span {
    background-color: #000000;
    transition: background-color 0.3s ease;
}

.navbar-container.background .logo-container a {
    color: #FFFFFF;
}

.navbar-container.background button span {
    background-color: #FFFFFF;
}

.logo-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar-links ul {
    margin: 0;
    padding: 0;
    display: flex;
}

.navbar-links li {
    list-style: none;
}

.navbar-links li:not(:nth-last-child(-n+2)) a {
    text-decoration: none;
    padding: .75rem 1rem;
    display: block;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 20px;
    height: 60px;
    line-height: 35px;
    -webkit-tap-highlight-color: transparent;
}

.navbar-container .navbar-links li {
    transition: transform 0.3s ease;
}

.navbar-container .navbar-links li:hover {
    transform: scale(1.08);
}

.navbar-container.background .navbar-links li {
    transition:  none;
}

.navbar-container.background .navbar-links li:hover {
    transform: none;
}

.navbar-container.background .navbar-links li a, .navbar-container.background .navbar-links li svg {
    color: white;
}

.navbar-container.background .navbar-links li:hover {
    background-color: #A9B993;
}

.cannabis {
    width: 36px;
    height: 36px;
    left: 10px;
    top: 20px;
}

.title {
    font-style: normal;
    font-weight: 200;
    font-size: 20px;
    height: 45.59px;
    line-height: 45.59px;
    margin: .5rem;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
    text-decoration: none
}

.navbar-container .logo-container {
    transition: transform 0.3s ease;
}

.navbar-container .logo-container:hover {
    transform: scale(1.08);
}

.navbar-container.background .logo-container:hover {
    background-color: #A9B993;
    transform: none;
}

.toggle-button {
    position: absolute;
    top: 1.2rem;
    right: 1rem;
    display: none;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    cursor:pointer;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 21px;
    -webkit-tap-highlight-color: transparent;
}

.toggle-button .bar {
    height: 3px;
    width: 100%;
    border-radius: 10px;
}

.store-banner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background: #447AB9;
    border-bottom: 0.5px solid #000000;
    padding: 5px 0 5px 15px;
    height: 60px;
}

.store-header {
    font-family: 'Southern Aire Personal Use Only';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    color: #fff;
    text-align: center;
    margin: auto;
}

.filter-products-mobile {
    background: #82A7D6;
    border-radius: 5px;
    font-family: 'Futura';
    font-style: normal;
    font-size: 16px;
    text-align: center;
    color: #fff;
	border: none;
	cursor: pointer;
    margin: auto;
    display: inline-block;
}

.filter-products-mobile:hover {
    background-color: #9EB8DA;
}

.filter-products-mobile div {
    text-align: center;
}

.filter-products__modal {
    display: flex;
    justify-content: center;
    margin-top: 5px;
}

@media (max-width: 980px) {
    .toggle-button {
        display: flex;
    }

    .navbar-links {
        display: none;
        width: 100%;
    }

    .navbar-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .navbar-links ul {
        width: 100%;
        flex-direction: column;
    }

    .navbar-links li {
        text-align: center;
    }

    .navbar-links li a {
        padding: .5rem 1rem;
    }
}