.reviews-container {
    margin-top: 2rem;
}

.reviews-container  div {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 28px;
}

.average-rating-container {
    display: flex;
}

.average-rating-container > .average-rating {
    font-size: 32px;
    align-self: center;
}

.average-stars-and-num-of-reviews {
    margin-left: 0.75rem;
}

.average-stars-and-num-of-reviews > div:last-child {
    font-size: 10px;
}

.reviews-btns-container {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}

.reviews-btns-container > button {
    width: 48%;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #EDF1F6;
    border-radius: 2px;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 16px;
    transition: all 0.2s ease;
}

.reviews-btns-container > button:last-child {
    background: #2C621A;
    color: #fff;
    border-radius: 5px;
    transition: all 0.2s ease;
}

.reviews-btns-container > button:first-child:hover {
    background: #F0F0F0;
}

.reviews-btns-container > button:last-child:hover {
    background: #3E7C2B;
}

.review-container {
    margin-top: 2rem;
}

.review-container > .rating-and-title {
    display: flex;
}

.review-container > .rating-and-title div {
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 16px;
}

.review-container > .rating-and-title > div:last-child {
    margin-left: 0.5rem;
}

.review-container > .review {
    margin-top: 0.5rem;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 16px;
}

.review-container > .review-author {
    margin-top: 0.5rem;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 12px;
}

@media (min-width: 980px) {
    .reviews-container {
        width: 55%;
    }
}