.admin-container {
    background: #F7F9FB;
}

.admin-container > div:first-child {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    padding-top: 1rem;
    margin: 0 20px 15px 20px;
}

.admin-container > div:first-child > div:last-child {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    color: #1A1A1A;
    text-align: center;
    margin-right: 1rem; /*Make headerText appear more centered*/
}

.admin-container > div:last-child {
    display: grid;
    place-items: center center;
    height: 100%;
}

.admin-container > div:last-child > span {
    display: inline-block;
    border: 1px solid #BCBDBC;
    width: 95%; 
    text-align: center;
    margin-bottom: 1rem;
}

.admin-filter-sort-btn.admin-header {
    background: #F7F9FB;
    border: 1px solid #BCBDBC;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #1A1A1A;
    cursor: pointer;
}

.admin-filter-sort-btn.admin-header > div:nth-child(2) {
    font-size: 14px !important;
}

.admin-filter-sort-btn-container.not-products {
    justify-content: flex-end !important;
}

.admin-filter-sort-btn-wrapper {
    display: inline-flex;
    position: relative;
    justify-content: center;
    max-width: 300px;
    width: 100%;
    margin: 0 0.5rem;
}

.export-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
	cursor: pointer;
    background: #F7F9FB;
    border: 1px solid #BCBDBC;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    height: 35px;
    transition: all 0.3s ease-in-out;
    width: 100%;
}

.export-btn:hover {
    background: #E6F3FF;
}

.export-btn > svg {
    padding-right: 0.25rem;
}

.export-btn > div {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #000000;
    padding-left: 0.25rem;
}

.export-btn.desktop > div{
    font-size: 16px;
}

.admin-filter-sort-export-dropdown {
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 2;
    color: #212529;
    background: #F7F9FB;
    border: 1px solid #BCBDBC;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.drop-down-export-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.3rem 0;
}

.drop-down-export-container > .order-customer-card-btn {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 16px;
    width: 70%;
    border-radius: 5px;
}

.admin-filter-sort-export-dropdown:hover {
    background: #E6F3FF;
}


@media (max-width: 420px) {
    .admin-filter-sort-btn.admin-header > div:nth-child(2) {
        font-size: 11px !important;
    }
}

@media (max-width: 620px) {
    .admin-filter-sort-btn-wrapper {
        width: 50%;
    }
    .admin-filter-sort-btn.admin-header {
        width: 50%;
    }
}

@media (min-width: 620px) and (max-width: 980px) {
    .admin-filter-sort-btn-container.admin-header {
        margin-left: 0;
        justify-content: center;
    }
}

@media (min-width: 980px) {
    .admin-container > div:first-child > svg:first-child {
        display: none;
    }
    .admin-filter-sort-btn-container.admin-header {
        justify-content: flex-end;
        margin-right: 3rem;
    }
    .admin-container {
        display: flex;
    }
    .admin-container > div:nth-child(2) {
        padding-top: 1.5rem;
    }
    .admin-container > div:last-child {
        display: none;
    }
    .admin-header-desktop-span {
        display: flex;
        justify-content: center;
        background: #F7F9FB;
    }

    .admin-header-desktop-span > span {
        display: inline-block;
        border: 1px solid #BCBDBC;
        width: 95%; 
        text-align: center;
        margin-bottom: 1rem;
    }
    .admin-filter-sort-export-dropdown {
        margin-top: -0.25rem;
    }
}