.product-card-container {
    background: #FFFFFF;
    box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    width: 90%;
    margin: 1.2rem auto;
    padding-bottom: 1.2rem;
    max-width: 354px;
    max-height: 556px;
}

.product-card-image-container {
    width: 95%;
    margin: 0 auto;
    overflow: hidden;
    padding-top: 0.5rem;
  }
  
.product-card-image-container img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }

.product-card-info-container {
    width: 80%;
    margin: 0 auto;
}

.product-card-info-container div,  .product-card-info-container a{
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 0.25rem;
}

.product-card-info-container > div:last-child {
    color: #2D3748;
}

.product-card-info-container .rating {
    margin-top: 1rem;
    align-items: center;
}

.num-of-reviews.product-card {
    margin-top: 0.3rem;
}

.product-card-container .add-to-cart-btn {
    margin-top: 0.5rem;
    height: 42px;
}

.product-card-info-container a {
    color: inherit;
    text-decoration: none;
}