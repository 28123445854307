@import url('https://fonts.googleapis.com/css2?family=Alex+Brush:wght@100;200;300;400;500;600;700;800;900&display=swap');

.landing-page-container {
    /* background-image: url('https://d1hf9un2w0qv80.cloudfront.net/background_image.svg'); */
    background-image: url('https://d1hf9un2w0qv80.cloudfront.net/home_background.webp');
    background-repeat: no-repeat;
    /* background-size: calc(1728px*1.15) calc(1117px*1.05); */
    background-position: center -360px;
    background-attachment: fixed;
}

.landing-page-text {
    position: relative;
    z-index: 1; /* to ensure the text is on top of the background image */
    transform: translate3d(0, 0, 0); /* to enable hardware acceleration for smoother scrolling */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
}

.landing-page-text > div:first-child {
    font-family: 'Alex Brush';
    font-weight: 400;
    font-size: 20px;
}

.landing-page-text > div:nth-child(2) {
    font-weight: 500;
    font-size: 40px;
}

.landing-page-text > img {
    width: 129px;
    height: 7px;
}

.birds-container {
    display: flex;
}

.birds-container > img:first-child {
    position: absolute;
    right: 80%;
    top: 36.61%;
    bottom: 59.72%;
}

.birds-container > img:last-child {
    position: absolute;
    left: 71.79%;
    right: 9.03%;
    top: 29.86%;
    bottom: 66.36%;
}

.meditating {
    padding-top: 14.8rem;
    margin: 0 auto;
    width: 320px;
    height: 569.5px;
    filter: drop-shadow(3px 6px 5px rgba(0, 0, 0, 0.5));
}

.home-page-content-wrapper {
    background-color: #B5BB8D;
    /* min-height: 25vh; */
    padding-bottom: 1.5rem;
}

.home-page-content-wrapper > div {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    color: #464646;
}

.home-page-header-text-and-link-wrapper {
    padding: 1rem 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.home-page-header-text-and-link-wrapper > div:last-child {
    font-size: 20px;
    text-decoration-line: underline;
    transition: all 0.1s ease-in-out;
}

.home-page-header-text-and-link-wrapper > div:last-child:hover {
    cursor: pointer;
    color:#303030;
}

.home-page-shop-content-container {
    display: flex;
    justify-content: center;
}

.home-page-content-wrapper.educational {
    background: #456649;
}

.home-page-content-wrapper.educational > div {
    color: #fff;
}

.home-page-content-wrapper.testomonials {
    background: #C2C5A2;
}

.home-page-content-wrapper.newsletter {
    background: #4A8038
}

.home-page-content-wrapper.newsletter > div {
    color: #fff;
}

.home-page-header-text-and-link-wrapper-newsletter {
    padding: 1rem 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.home-page-content-wrapper > .new-arrivals-carousel-wrapper {
    max-width: 2400px;
    margin: 0 auto;
}

.newsletter-signup-wrapper {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    max-width: 400px;
}

.newsletter-signup-wrapper button {
    background: #E9E4CA;
    border-radius: 5px;
    font-size: 16px;
    color: #27482B;
}

.newsletter-signup-wrapper button:hover {
    cursor: pointer;
    background: #C2C5A2;
}

@media (min-width: 1800px) {
    .landing-page-container {
        background-size:  100% 100%;
        background-position: center -10vh;
    }
}

@media (min-width: 1800px) and (min-height: 1000px){
    .landing-page-container {
        background-size:  100% 100%;
        background-position: center -20vh;
    }
}


@media (min-width: 3000px) and (min-height: 1800px) {
    .landing-page-container {
        background-position: center -50vh;
    }
}

@media (min-width: 980px) {
    .landing-page-text > div:first-child {
        font-size: 36px;
    }

    .landing-page-text > div:nth-child(2) {
        font-size: 80px;
    }
    .meditating {
        padding-top: 11.55rem;
        width: 320px;
        height: 517.5px;
    }
    .landing-page-text > img {
        width: 320px;
        height: 17.5px;
    }
    .home-page-header-text-and-link-wrapper > div:first-child,
    .home-page-header-text-and-link-wrapper-newsletter > div:first-child {
        margin-left: 4rem;
        font-size: 60px;
    }
    .home-page-header-text-and-link-wrapper > div:last-child {
        font-size: 30px;
        margin-right: 4rem;
    }
}


