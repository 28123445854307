.admin-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    overflow-x: hidden;
    transition: .25s transform ease;
    z-index: 99999;
    transform: translateX(-100%);
  }

.admin-nav > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 30%;
}

.admin-nav > div:nth-child(2) > div:first-child {
    font-family: 'Overseer';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
}

.admin-nav > div:nth-child(2) > div:last-child {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    margin-top: -0.5rem;
}
  
.admin-nav.open {
transform: translateX(0%);
}

.main-content.open {
    transform: translate3d(100%, 0, 0);
}
  