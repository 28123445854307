.pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem 0;
    padding-right: 0.25rem;
}

.pagination > svg:hover {
    cursor: pointer;
}

.pagination > div {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #767676;
    padding: 0 0.25rem;
    display: flex;
    align-items: center;
}

.pagination > div > span {
    color: #1A1A1A;
    padding-left: 0.5rem;
    margin-right: -0.25rem;
}

@media (min-width: 980px) {
    .pagination {
        padding-right: 2rem;
    }
}